import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/aboutus', title: 'About Us', type: 'link', active: false, 
			// children: [
				// { path: '/aboutus', title: 'About us', type: 'link' },
				// { path: '/who-we-are', title: 'who we are', type: 'link' },
				// { path: '/award', title: 'award', type: 'link' },
			// ]
		},
		{
			title: 'Services', type: 'sub',  megaMenu: true,  active: false, children: [
						{
							"title": "Website Development",
							"type": "sub",
							"active": false,
							"children": [
								{ "path": "/Website-development", "title": "Website Development", "type": "link" },
								{ "path": "/Web-design", "title": "Web Design", "type": "link" },
								{ "path": "/Ecommerce-development", "title": "Ecommerce Development", "type": "link" },
								{ "path": "/WordPress-development", "title": "WordPress Development", "type": "link" },
								{ "path": "/CMS-development", "title": "CMS Development", "type": "link" }
							]
						},
						{
							"title": "Mobile App Development",
							"type": "sub",
							"active": false,
							"children": [
								{ "path": "/android-app-development", "title": "Android App Development", "type": "link" },
								{ "path": "/iOS-app-development", "title": "iOS App Development", "type": "link" },
								{ "path": "/hybrid-app-development", "title": "Hybrid App Development", "type": "link" },
								{ "path": "/react-app-development", "title": "React Native Development", "type": "link" },
								{ "path": "/mobile-app-design", "title": "Mobile App Design", "type": "link" }
							]
						},
						{
							"title": "Software Development",
							"type": "sub",
							"active": false,
							"children": [
								{ "path": "/custom-software-development", "title": "Custom Software Development", "type": "link" },
								{ "path": "/enterprise-software-development", "title": "Enterprise Software Development", "type": "link" },
								{ "path": "/saas-development", "title": "SaaS Development", "type": "link" },
								{ "path": "/product-engineering", "title": "Product Engineering", "type": "link" },
								{ "path": "/software-maintenance-support", "title": "Software Maintenance & Support", "type": "link" }
							]
						},
						{
							"title": "UI/UX Design",
							"type": "sub",
							"active": false,
							"children": [
								{ "path": "/ui-ux-design", "title": "UI/UX Design", "type": "link" },
								{ "path": "/wireframing", "title": "Wireframing", "type": "link" },
								{ "path": "/prototyping", "title": "Prototyping", "type": "link" },
								{ "path": "/ux-research", "title": "UX Research", "type": "link" },
								// { "path": "/branding", "title": "Branding", "type": "link" }
							]
						},
						{
							"title": "Quality Assurance",
							"type": "sub",
							"active": false,
							"children": [
								{ "path": "/manual-testing", "title": "Manual Testing", "type": "link" },
								{ "path": "/automated-testing", "title": "Automated Testing", "type": "link" },
								{ "path": "/performance-testing", "title": "Performance Testing", "type": "link" },
								{ "path": "/security-testing", "title": "Security Testing", "type": "link" },
								{ "path": "/user-acceptance-testing", "title": "User Acceptance Testing", "type": "link" }
							]
						}
					]
					
		},
		// {
		// 	title: 'industry', type: 'sub', active: false, children: [
		// 		{
		// 			"title": "Education",
		// 			"type": "link",
		// 			"active": false,
		// 			"path": "/education" 
		// 		},
		// 		{
		// 			"title": "healthcare",
		// 			"type": "link",
		// 			"active": false,
		// 			"path": "/education" 
		// 		},
		// 		{
		// 			"title": "fintech",
		// 			"type": "link",
		// 			"active": false,
		// 			"path": "/education" 
		// 		},
		// 		{
		// 			"title": "Food Industry",
		// 			"type": "link",
		// 			"active": false,
		// 			"path": "/education" 
		// 		},
		// 		{
		// 			"title": "Retail & E-commerce",
		// 			"type": "link",
		// 			"active": false,
		// 			"path": "/education" 
		// 		},
		// 		{
		// 			"title": "Entertainment",
		// 			"type": "link",
		// 			"active": false,
		// 			"path": "/education" 
		// 		},
		// 		{
		// 			"title": "Social Media",
		// 			"type": "link",
		// 			"active": false,
		// 			"path": "/education" 
		// 		},
		// 			]
		// },

		{ title: 'career', type: 'link', path: '/career',  active: false },
		{ title: 'PORTFOLIO', type: 'link', path: '/pages/portfolio/',  active: false },
		// { path: '/career', title: 'career', type: 'link' },
		
		// { title: 'services', type: 'link', path: 'https://aquilatechsol.thevaishnavtech.com/services/category/services/',  active: false },
		// { title: 'blogs', type: 'link', path: 'https://aquilatechsol.thevaishnavtech.com/services/category/blog/',  active: false },
		// { title: 'contact us', type: 'link', path: 'https://aquilatechsol.thevaishnavtech.com/services/contact-us/',  active: false }
		
			
	];

	// { path: '/pages/blog/details', title: 'blog-details', type: 'link' }
	LEFTMENUITEMS: Menu[] = [
		{
			title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
			  {
				  title: 'mens fashion',  type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'top',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'shirts',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' }
				  ]
			  },
			  {
				  title: 'women fashion',  type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'dresses',  type: 'link' },
					  { path: '/home/fashion', title: 'skirts',  type: 'link' },
					  { path: '/home/fashion', title: 'westarn wear',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom wear',  type: 'link' }
				  ]
			  },
			]
		},
		{
			title: 'contact-us', type: 'sub', active: false, children: [
			 
			]
		},
		{
			title: 'footwear', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'sport shoes', type: 'link' },
			  { path: '/home/fashion', title: 'formal shoes', type: 'link' },
			  { path: '/home/fashion', title: 'casual shoes', type: 'link' }
			]
		},
		{
			path: '/home/fashion', title: 'watches', type: 'link'
		},
		{
			title: 'Accessories', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'fashion jewellery', type: 'link' },
			  { path: '/home/fashion', title: 'caps and hats', type: 'link' },
			  { path: '/home/fashion', title: 'precious jewellery', type: 'link' },
			  {
				  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'necklaces',  type: 'link' },
					  { path: '/home/fashion', title: 'earrings',  type: 'link' },
					  { path: '/home/fashion', title: 'rings & wrist wear',  type: 'link' },
					  {
						  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
							  { path: '/home/fashion', title: 'ties',  type: 'link' },
							  { path: '/home/fashion', title: 'cufflinks',  type: 'link' },
							  { path: '/home/fashion', title: 'pockets squares',  type: 'link' },
							  { path: '/home/fashion', title: 'helmets',  type: 'link' },
							  { path: '/home/fashion', title: 'scarves',  type: 'link' },
							  {
								  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
									  { path: '/home/fashion', title: 'accessory gift sets',  type: 'link' },
									  { path: '/home/fashion', title: 'travel accessories',  type: 'link' },
									  { path: '/home/fashion', title: 'phone cases',  type: 'link' }
								  ]
							  },
						]
					  }
				  ]
			  },
			]
		},
		{
			path: '/home/fashion', title: 'house of design', type: 'link'
		},
		
	
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
